import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const ManagedIT = () => {
  return (
    <div className="relative px-6 md:px-12 container mx-auto grid lg:grid-cols-2 gap-16 xl:px-0">
      <div>
        <h3 className="text-md font-sans inline-block md:text-lg tracking-wide text-purple mb-4 font-medium">
          We’re Here To Help
        </h3>
        <h4 className="text-black text-3xl font-display font-medium leading-normal lg:leading-tight lg:text-5xl mb-4">
          Your Business <br />
          Is In Safe Hands
        </h4>
        <p className="text-black-light">
          iQuest is one of Perth’s most successful IT consultant groups,
          providing end-to-end business solutions that span a broad spectrum of
          infrastructure technologies.
        </p>
        <p className="text-black-light">
          From consulting and fully managed IT support services to business IT
          cloud solutions and long term IT strategies, iQuest is the trusted IT
          company and technology partner behind some of Perth’s biggest
          companies, with a 96% client retention rate since 2004.
        </p>
        <p className="text-black-light">
          We pride ourselves on providing a personal, customised Perth IT
          support service that’s tailored to each of our clients business needs.
          By delivering the very best solutions, we’re helping Perth businesses
          minimise risk, improve performance and future proof their technology.
        </p>
        <Link to={"/about"} className="btn text-purple !border-purple mt-6">
          Learn More
        </Link>
      </div>

      <div className="relative lg:absolute 2xl:relative right-0 top-0 w-full lg:!w-2/3">
        <SVG />
        <StaticImage
          className="lg:translate-x-1/3 translate-y-16 aspect-1 rounded-full"
          src="../images/iQuest-IT-Mockup.jpg"
          alt="iQuest Computer Mockup"
          placeholder="dominantColor"
          layout="constrained"
          quality="100"
        />
      </div>
    </div>
  )
}

const SVG = () => (
  <div className="absolute left-[0] lg:left-0 top-0 w-64 h-64 z-10 translate-x-full">
    <svg
      className="animate-spin-slow"
      xmlns="http://www.w3.org/2000/svg"
      id="Group_1106"
      data-name="Group 1106"
      width="247.01"
      height="247.434"
      viewBox="0 0 247.01 247.434"
    >
      <path
        id="Path_448"
        data-name="Path 448"
        d="M172.743,10.273l2.066.843-3.042,7.452a4.33,4.33,0,0,1-1.456,1.92,4.23,4.23,0,0,1-2.21.79,6.433,6.433,0,0,1-4.873-1.989,4.211,4.211,0,0,1-1.022-2.11,4.351,4.351,0,0,1,.306-2.39l3.042-7.451,2.065.842-2.97,7.28a2.718,2.718,0,0,0-.2,1.4,2.386,2.386,0,0,0,.532,1.234A3.342,3.342,0,0,0,167.7,19.2a2.376,2.376,0,0,0,1.244-.509,2.737,2.737,0,0,0,.83-1.142Z"
        fill="#0091fc"
      />
      <path
        id="Path_449"
        data-name="Path 449"
        d="M172.8,23.847l5.815-10.86,1.967,1.054-4.931,9.212,4.784,2.561-.883,1.649Z"
        fill="#0091fc"
      />
      <path
        id="Path_450"
        data-name="Path 450"
        d="M181.693,28.764l6.78-10.284,1.864,1.228-5.752,8.723,4.53,2.986-1.03,1.562Z"
        fill="#0091fc"
      />
      <path
        id="Path_451"
        data-name="Path 451"
        d="M196.142,23.535l1.971,1.572-1.042,6.14.094.074,5.754-2.382,1.97,1.572-8.319,3.245L193.7,37.349l-1.74-1.388,2.865-3.593Z"
        fill="#0091fc"
      />
      <path
        id="Path_452"
        data-name="Path 452"
        d="M211.51,36.677l1.817,2.039L209.1,47.385l.1.108,9.1-3.21,1.816,2.039-9.2,8.2-1.425-1.6,6.318-5.632-.076-.085-8.556,3.066-1.065-1.194,4.04-8.162-.076-.085-6.331,5.643-1.425-1.6Z"
        fill="#0091fc"
      />
      <path
        id="Path_453"
        data-name="Path 453"
        d="M213.534,58.192l-1.345-1.965L224.8,52.848l1.556,2.273-7.714,10.547L217.3,63.7l6.222-8.245-.054-.08Zm4.03-2.663,3.668,5.361L219.754,61.9l-3.67-5.362Z"
        fill="#0091fc"
      />
      <path
        id="Path_454"
        data-name="Path 454"
        d="M235.322,70.719l-10.9,5.736L223.5,74.7l4.723-9.045-.048-.091-7.425,3.908L219.709,67.5l10.9-5.736.93,1.768-4.732,9.042.05.1,7.432-3.91Z"
        fill="#0091fc"
      />
      <path
        id="Path_455"
        data-name="Path 455"
        d="M226.191,80.6l-.857-2.221,13.054-.385.991,2.571-9.932,8.483-.857-2.223,7.954-6.593-.035-.09Zm4.535-1.664L233.062,85l-1.672.644-2.336-6.06Z"
        fill="#0091fc"
      />
      <path
        id="Path_456"
        data-name="Path 456"
        d="M240.09,96.4a3.316,3.316,0,0,0,.743-.619,2.815,2.815,0,0,0,.485-.773,2.63,2.63,0,0,0,.2-.912,3.578,3.578,0,0,0-.122-1.025,3.2,3.2,0,0,0-.928-1.585,3.344,3.344,0,0,0-1.727-.808,5.611,5.611,0,0,0-2.384.165,5.7,5.7,0,0,0-2.177,1.022,3.171,3.171,0,0,0-1.142,3.437,3.46,3.46,0,0,0,.761,1.483,2.527,2.527,0,0,0,1.259.768,3.143,3.143,0,0,0,1.606-.034l.051.509-.843-3.236,1.688-.44,1.262,4.836-1.432.374a5.343,5.343,0,0,1-2.818.04,4.584,4.584,0,0,1-2.187-1.344,6.2,6.2,0,0,1-1.3-5.545,5.242,5.242,0,0,1,1.607-2.489,7.34,7.34,0,0,1,3.074-1.546,7.8,7.8,0,0,1,2.686-.263,5.762,5.762,0,0,1,2.23.646,5.106,5.106,0,0,1,1.662,1.418,5.728,5.728,0,0,1,.981,2.057,5.905,5.905,0,0,1,.195,1.92,5.172,5.172,0,0,1-.42,1.735,4.685,4.685,0,0,1-.972,1.425,4.6,4.6,0,0,1-1.458.984Z"
        fill="#0091fc"
      />
      <path
        id="Path_457"
        data-name="Path 457"
        d="M233.026,103.453l12.187-1.8,1.17,7.926-1.851.275-.843-5.719-3.309.488.784,5.308-1.851.273-.784-5.308-3.326.491.851,5.766-1.85.272Z"
        fill="#0091fc"
      />
      <path
        id="Path_458"
        data-name="Path 458"
        d="M234.683,118.37l-.169-4.171,12.308-.5.173,4.255a6.385,6.385,0,0,1-.607,3.183,4.976,4.976,0,0,1-2.03,2.114,8.023,8.023,0,0,1-6.6.266,4.971,4.971,0,0,1-2.206-1.962,6.555,6.555,0,0,1-.87-3.183m1.85-2.019.075,1.833a4.417,4.417,0,0,0,.559,2.126,2.987,2.987,0,0,0,1.465,1.235,6.614,6.614,0,0,0,4.7-.191,2.98,2.98,0,0,0,1.348-1.335,4.278,4.278,0,0,0,.38-2.114l-.077-1.9Z"
        fill="#0091fc"
      />
      <path
        id="Rectangle_227"
        data-name="Rectangle 227"
        d="M234.325,134.16l.184-2.224,12.277,1.018-.184,2.223Z"
        fill="#0091fc"
      />
      <path
        id="Path_459"
        data-name="Path 459"
        d="M244.485,138.3l1.846.3-1.556,9.7-1.848-.3.6-3.759-10.312-1.653.351-2.185,10.316,1.655Z"
        fill="#0091fc"
      />
      <path
        id="Path_460"
        data-name="Path 460"
        d="M237.345,162.577a1.646,1.646,0,0,0,1.384-.3,2.734,2.734,0,0,0,.909-1.4,3.068,3.068,0,0,0,.163-1.2,1.779,1.779,0,0,0-.313-.9,1.389,1.389,0,0,0-.7-.508,1.231,1.231,0,0,0-.69-.034,1.543,1.543,0,0,0-.6.317,3.306,3.306,0,0,0-.519.548,7.647,7.647,0,0,0-.44.648l-.626,1.013a8.089,8.089,0,0,1-.826,1.148,4.879,4.879,0,0,1-1.018.894,3.116,3.116,0,0,1-1.216.467,3.046,3.046,0,0,1-1.422-.137,3.329,3.329,0,0,1-1.645-1.1,3.644,3.644,0,0,1-.736-1.912,6.251,6.251,0,0,1,.328-2.544,6.38,6.38,0,0,1,1.181-2.24,3.689,3.689,0,0,1,1.739-1.176,3.838,3.838,0,0,1,2.178.022l-.667,2.092a1.859,1.859,0,0,0-1.141.053,2.1,2.1,0,0,0-.884.674,3.589,3.589,0,0,0-.583,1.137,3.434,3.434,0,0,0-.179,1.285,2.023,2.023,0,0,0,.323,1.008,1.469,1.469,0,0,0,.783.584,1.186,1.186,0,0,0,.844-.028,2.124,2.124,0,0,0,.739-.575,7.877,7.877,0,0,0,.715-.987l.771-1.226a5.581,5.581,0,0,1,1.861-1.93,2.719,2.719,0,0,1,2.288-.2,3.164,3.164,0,0,1,1.637,1.143,3.9,3.9,0,0,1,.722,1.925,5.658,5.658,0,0,1-.271,2.344,5.466,5.466,0,0,1-1.131,2.054,3.771,3.771,0,0,1-1.67,1.1,3.218,3.218,0,0,1-1.935,0Z"
        fill="#0091fc"
      />
      <path
        id="Path_461"
        data-name="Path 461"
        d="M227.2,164.425l11.3,4.907-3.192,7.349-1.716-.745,2.3-5.3-3.068-1.332-2.137,4.922-1.716-.745,2.137-4.921-3.084-1.339-2.322,5.345-1.716-.745Z"
        fill="#0091fc"
      />
      <path
        id="Path_462"
        data-name="Path 462"
        d="M224.524,182.593l-6.36-.068,1.217-2.173,6.334.115Zm-1.759-8.281,10.746,6.022-2.258,4.03a5.2,5.2,0,0,1-1.6,1.839,3.394,3.394,0,0,1-1.919.6,4.177,4.177,0,0,1-2.045-.577,4.038,4.038,0,0,1-1.546-1.442,3.323,3.323,0,0,1-.449-1.944,5.472,5.472,0,0,1,.759-2.319l1.608-2.871,1.616.906-1.462,2.609a3.39,3.39,0,0,0-.462,1.3,1.606,1.606,0,0,0,.209,1.018,2.191,2.191,0,0,0,.837.762,2.277,2.277,0,0,0,1.106.323,1.669,1.669,0,0,0,1-.341,3.276,3.276,0,0,0,.88-1.072l1-1.783-9.12-5.111Z"
        fill="#0091fc"
      />
      <path
        id="Path_463"
        data-name="Path 463"
        d="M226.275,192.531l-9.764-3-.073.1,6.057,8.221-1.42,2-7.534-10.67,1.593-2.247,12.558,3.591Z"
        fill="#0091fc"
      />
      <path
        id="Rectangle_228"
        data-name="Rectangle 228"
        d="M208.1,196.067l1.434-1.71,9.44,7.915-1.435,1.71Z"
        fill="#0091fc"
      />
      <path
        id="Path_464"
        data-name="Path 464"
        d="M204.572,211.649l1.583-1.6a2.84,2.84,0,0,0,.947.439,2.652,2.652,0,0,0,.974.056,2.72,2.72,0,0,0,.938-.3,3.508,3.508,0,0,0,.852-.641,3.221,3.221,0,0,0,.9-1.619,3.351,3.351,0,0,0-.189-1.9,6.581,6.581,0,0,0-3.373-3.321,3.274,3.274,0,0,0-1.887-.16,3.227,3.227,0,0,0-1.6.913,3.555,3.555,0,0,0-.626.841,2.827,2.827,0,0,0-.3.927,2.656,2.656,0,0,0,.044.965,2.7,2.7,0,0,0,.425.94l-1.591,1.59a4.875,4.875,0,0,1-.526-5.039,5.793,5.793,0,0,1,1.176-1.654,5.6,5.6,0,0,1,2.573-1.532,5.246,5.246,0,0,1,2.957.1,8.251,8.251,0,0,1,4.837,4.8,5.3,5.3,0,0,1,.122,2.949,5.583,5.583,0,0,1-1.5,2.582,6.168,6.168,0,0,1-1.551,1.157,4.938,4.938,0,0,1-1.71.525,4.623,4.623,0,0,1-1.768-.145,5.152,5.152,0,0,1-1.714-.872"
        fill="#0091fc"
      />
      <path
        id="Path_465"
        data-name="Path 465"
        d="M196.794,207.591l7.781,9.551-6.212,5.061-1.182-1.451,4.481-3.651-2.106-2.588L195.4,217.9l-1.181-1.451,4.16-3.388-2.123-2.607-4.519,3.68-1.182-1.449Z"
        fill="#0091fc"
      />
      <path
        id="Path_466"
        data-name="Path 466"
        d="M187.66,225.465a1.64,1.64,0,0,0,1.266.634,2.731,2.731,0,0,0,1.59-.523,3.072,3.072,0,0,0,.883-.836,1.764,1.764,0,0,0,.323-.9,1.389,1.389,0,0,0-.229-.836,1.232,1.232,0,0,0-.515-.46,1.544,1.544,0,0,0-.668-.132,3.331,3.331,0,0,0-.748.1,7.594,7.594,0,0,0-.75.229l-1.123.4a8.157,8.157,0,0,1-1.365.375,4.777,4.777,0,0,1-1.353.057,3.116,3.116,0,0,1-1.24-.4,3.062,3.062,0,0,1-1.02-1,3.327,3.327,0,0,1-.588-1.893,3.639,3.639,0,0,1,.627-1.949,6.242,6.242,0,0,1,1.852-1.775,6.382,6.382,0,0,1,2.327-1,3.691,3.691,0,0,1,2.091.177,3.84,3.84,0,0,1,1.682,1.383l-1.832,1.21a1.868,1.868,0,0,0-.922-.674,2.092,2.092,0,0,0-1.112-.03,3.579,3.579,0,0,0-1.168.519,3.458,3.458,0,0,0-.946.887,2.053,2.053,0,0,0-.382.988,1.473,1.473,0,0,0,.243.946,1.2,1.2,0,0,0,.675.509,2.123,2.123,0,0,0,.938.015,7.8,7.8,0,0,0,1.176-.318l1.368-.472a5.6,5.6,0,0,1,2.659-.333,2.722,2.722,0,0,1,1.9,1.284,3.163,3.163,0,0,1,.557,1.918,3.886,3.886,0,0,1-.647,1.952,5.633,5.633,0,0,1-1.682,1.655,5.477,5.477,0,0,1-2.171.889,3.783,3.783,0,0,1-1.99-.192,3.217,3.217,0,0,1-1.5-1.217Z"
        fill="#0091fc"
      />
      <path
        id="Path_467"
        data-name="Path 467"
        d="M174.29,222.892l5.2,11.166L175.3,236.01a4.92,4.92,0,0,1-2.385.527,3.566,3.566,0,0,1-1.938-.7,4.684,4.684,0,0,1-1.773-3.81,3.545,3.545,0,0,1,.725-1.933,5.008,5.008,0,0,1,1.954-1.492l2.775-1.292.774,1.662-2.5,1.166a2.827,2.827,0,0,0-1.109.836,1.84,1.84,0,0,0-.376,1.051,2.537,2.537,0,0,0,.259,1.16,2.5,2.5,0,0,0,.719.939,1.8,1.8,0,0,0,1.044.373,2.921,2.921,0,0,0,1.357-.324l1.855-.864-4.416-9.476Z"
        fill="#0091fc"
      />
      <path
        id="Path_468"
        data-name="Path 468"
        d="M163.872,227.567l4.057,11.632-7.565,2.639-.616-1.766,5.458-1.9-1.1-3.163-5.07,1.77-.616-1.766,5.066-1.768-1.107-3.175-5.5,1.92-.617-1.766Z"
        fill="#0091fc"
      />
      <path
        id="Path_469"
        data-name="Path 469"
        d="M148.71,237.913l-4.262-4.721,2.422-.578,4.21,4.734Zm4.894-6.907,2.861,11.983-4.495,1.072a5.213,5.213,0,0,1-2.434.075,3.412,3.412,0,0,1-1.742-1,4.146,4.146,0,0,1-.962-1.9,4.031,4.031,0,0,1,.011-2.115,3.324,3.324,0,0,1,1.125-1.647,5.455,5.455,0,0,1,2.219-1.015l3.2-.765.431,1.8-2.909.693a3.413,3.413,0,0,0-1.268.541,1.622,1.622,0,0,0-.606.843,2.208,2.208,0,0,0,.008,1.134,2.275,2.275,0,0,0,.512,1.031,1.662,1.662,0,0,0,.93.508,3.3,3.3,0,0,0,1.385-.08l1.989-.475-2.427-10.169Z"
        fill="#0091fc"
      />
      <path
        id="Path_470"
        data-name="Path 470"
        d="M144.193,243.836l.241,1.855-9.747,1.267-.241-1.855,3.776-.491-1.347-10.36,2.2-.286,1.345,10.362Z"
        fill="#0091fc"
      />
      <path
        id="Path_471"
        data-name="Path 471"
        d="M130.711,234.926l.231,12.317-2.231.041-.1-5.213-5.707.108.1,5.213-2.237.042-.232-12.317,2.237-.042.1,5.233,5.707-.108-.1-5.232Z"
        fill="#0091fc"
      />
      <path
        id="Path_472"
        data-name="Path 472"
        d="M109.277,237.683a2.549,2.549,0,0,1,1.275.518,2.718,2.718,0,0,1,.835,1.07,2.653,2.653,0,0,1-.327,2.642,2.722,2.722,0,0,1-1.07.831,2.654,2.654,0,0,1-2.641-.325,2.755,2.755,0,0,1-.832-1.067,2.656,2.656,0,0,1,.327-2.641,2.71,2.71,0,0,1,1.067-.835,2.533,2.533,0,0,1,1.366-.192"
        fill="#0091fc"
      />
      <path
        id="Path_473"
        data-name="Path 473"
        d="M98.965,232.4l-3.107,11.924-7.637-1.99.472-1.811,5.477,1.429.843-3.236-4.953-1.292.472-1.81,4.953,1.291,1.32-5.063Z"
        fill="#0091fc"
      />
      <path
        id="Path_474"
        data-name="Path 474"
        d="M77.243,238.449l-2.088-.788L78,230.131a4.329,4.329,0,0,1,1.4-1.958,4.21,4.21,0,0,1,2.189-.848,6.435,6.435,0,0,1,4.924,1.858,4.209,4.209,0,0,1,1.078,2.082,4.338,4.338,0,0,1-.243,2.4L84.5,241.194l-2.088-.788,2.777-7.355a2.741,2.741,0,0,0,.167-1.4,2.407,2.407,0,0,0-.565-1.22,3.351,3.351,0,0,0-2.749-1.038,2.377,2.377,0,0,0-1.23.542,2.763,2.763,0,0,0-.8,1.164Z"
        fill="#0091fc"
      />
      <path
        id="Path_475"
        data-name="Path 475"
        d="M76.844,224.888,71.32,235.9l-1.994-1,4.685-9.338-4.85-2.433.84-1.672Z"
        fill="#0091fc"
      />
      <path
        id="Path_476"
        data-name="Path 476"
        d="M67.812,220.2l-6.506,10.463-1.9-1.18,5.52-8.871-4.606-2.866.981-1.588Z"
        fill="#0091fc"
      />
      <path
        id="Path_477"
        data-name="Path 477"
        d="M53.5,225.8l-2.011-1.52.88-6.164-.1-.073-5.689,2.532-2.011-1.52L52.8,215.6l2.769-3.667,1.776,1.342-2.769,3.667Z"
        fill="#0091fc"
      />
      <path
        id="Path_478"
        data-name="Path 478"
        d="M37.788,213.066l-1.869-1.99,4-8.777-.1-.1-9.01,3.445-1.869-1.99,8.979-8.433,1.466,1.561-6.164,5.789.079.083,8.471-3.283,1.1,1.166L39.042,208.8l.077.083,6.182-5.813,1.466,1.561Z"
        fill="#0091fc"
      />
      <path
        id="Path_479"
        data-name="Path 479"
        d="M35.208,191.614l1.4,1.93-12.522,3.708-1.615-2.232L29.9,184.28l1.4,1.931-6,8.4.057.079Zm-3.959,2.768-3.808-5.263,1.457-1.05,3.808,5.262Z"
        fill="#0091fc"
      />
      <path
        id="Path_480"
        data-name="Path 480"
        d="M13.106,179.683l10.746-6.024.97,1.733-4.481,9.166.05.09,7.32-4.1,1.091,1.947-10.746,6.024-.976-1.742,4.49-9.164-.053-.094-7.325,4.1Z"
        fill="#0091fc"
      />
      <path
        id="Path_481"
        data-name="Path 481"
        d="M21.962,169.549l.916,2.2-13.04.731-1.059-2.546,9.7-8.743.916,2.2-7.776,6.8.036.09Zm-4.489,1.783-2.5-6,1.654-.688,2.5,6Z"
        fill="#0091fc"
      />
      <path
        id="Path_482"
        data-name="Path 482"
        d="M7.649,154.119a3.3,3.3,0,0,0-.726.639,2.821,2.821,0,0,0-.465.786,2.6,2.6,0,0,0-.171.917,3.569,3.569,0,0,0,.15,1.021,3.187,3.187,0,0,0,.97,1.56,3.335,3.335,0,0,0,1.746.762,5.6,5.6,0,0,0,2.379-.227,5.7,5.7,0,0,0,2.149-1.079,3.171,3.171,0,0,0,1.05-3.468,3.442,3.442,0,0,0-.8-1.462,2.532,2.532,0,0,0-1.28-.734,3.136,3.136,0,0,0-1.6.075l-.065-.507.929,3.212-1.674.485-1.39-4.8,1.422-.411a5.335,5.335,0,0,1,2.815-.115,4.6,4.6,0,0,1,2.223,1.286,6.2,6.2,0,0,1,1.445,5.509,5.228,5.228,0,0,1-1.54,2.531,7.347,7.347,0,0,1-3.033,1.626,7.783,7.783,0,0,1-2.677.334,5.778,5.778,0,0,1-2.246-.587,5.11,5.11,0,0,1-1.7-1.374,5.713,5.713,0,0,1-1.036-2.029,5.931,5.931,0,0,1-.244-1.914,5.137,5.137,0,0,1,.374-1.746,4.746,4.746,0,0,1,.933-1.451,4.584,4.584,0,0,1,1.43-1.026Z"
        fill="#0091fc"
      />
      <path
        id="Path_483"
        data-name="Path 483"
        d="M14.521,146.879,2.386,149,1.008,141.1l1.843-.323.995,5.7,3.3-.575-.923-5.286,1.844-.322.922,5.286L12.3,145l-1-5.742,1.841-.321Z"
        fill="#0091fc"
      />
      <path
        id="Path_484"
        data-name="Path 484"
        d="M12.484,132.022l.279,4.166-12.291.823-.286-4.249a6.377,6.377,0,0,1,.525-3.2A4.96,4.96,0,0,1,2.684,127.4a7.128,7.128,0,0,1,3.241-.929,7.155,7.155,0,0,1,3.349.49,4.967,4.967,0,0,1,2.255,1.906,6.546,6.546,0,0,1,.953,3.158m-1.8,2.068-.122-1.831a4.419,4.419,0,0,0-.615-2.111,2.99,2.99,0,0,0-1.5-1.2,5.627,5.627,0,0,0-2.379-.275,5.592,5.592,0,0,0-2.316.589,2.982,2.982,0,0,0-1.3,1.376,4.3,4.3,0,0,0-.324,2.122l.127,1.9Z"
        fill="#0091fc"
      />
      <path
        id="Rectangle_229"
        data-name="Rectangle 229"
        d="M0,117.752l.126-2.228,12.3.7-.127,2.227Z"
        fill="#0091fc"
      />
      <path
        id="Path_485"
        data-name="Path 485"
        d="M2.161,112.347.306,112.1l1.3-9.742,1.855.248-.5,3.774,10.353,1.378-.293,2.2L2.665,108.573Z"
        fill="#0091fc"
      />
      <path
        id="Path_486"
        data-name="Path 486"
        d="M8.663,87.878a1.648,1.648,0,0,0-1.376.338,2.739,2.739,0,0,0-.871,1.429,3.062,3.062,0,0,0-.131,1.206,1.762,1.762,0,0,0,.336.9,1.386,1.386,0,0,0,.715.489,1.226,1.226,0,0,0,.691.016,1.526,1.526,0,0,0,.594-.333,3.239,3.239,0,0,0,.5-.561,7.323,7.323,0,0,0,.422-.659l.6-1.03a7.954,7.954,0,0,1,.8-1.169,4.781,4.781,0,0,1,.993-.921,3.1,3.1,0,0,1,1.2-.5,3.066,3.066,0,0,1,1.424.1,3.321,3.321,0,0,1,1.674,1.062,3.636,3.636,0,0,1,.786,1.889,6.254,6.254,0,0,1-.261,2.554,6.367,6.367,0,0,1-1.122,2.27A3.694,3.694,0,0,1,13.93,96.18a3.836,3.836,0,0,1-2.174.03l.611-2.108a1.868,1.868,0,0,0,1.139-.083,2.1,2.1,0,0,0,.866-.7,3.552,3.552,0,0,0,.553-1.152,3.418,3.418,0,0,0,.145-1.289,2.035,2.035,0,0,0-.348-1,1.476,1.476,0,0,0-.8-.563,1.188,1.188,0,0,0-.845.05,2.138,2.138,0,0,0-.724.6,7.809,7.809,0,0,0-.688,1.005l-.738,1.245a5.568,5.568,0,0,1-1.81,1.978,2.717,2.717,0,0,1-2.281.255,3.163,3.163,0,0,1-1.667-1.1A3.9,3.9,0,0,1,4.4,91.442a5.665,5.665,0,0,1,.208-2.351,5.476,5.476,0,0,1,1.083-2.085,3.8,3.8,0,0,1,1.639-1.14,3.244,3.244,0,0,1,1.929-.05Z"
        fill="#0091fc"
      />
      <path
        id="Path_487"
        data-name="Path 487"
        d="M18.747,85.775,7.322,81.167l3-7.431,1.734.7L9.891,79.8l3.1,1.25L15,76.072l1.735.7-2.007,4.975L17.844,83l2.18-5.406,1.734.7Z"
        fill="#0091fc"
      />
      <path
        id="Path_488"
        data-name="Path 488"
        d="M22.922,75.773l-10.9-5.736,2.151-4.088a5.205,5.205,0,0,1,1.547-1.881,3.4,3.4,0,0,1,1.9-.651,4.161,4.161,0,0,1,2.059.524,4.032,4.032,0,0,1,1.584,1.4,3.318,3.318,0,0,1,.5,1.931,5.465,5.465,0,0,1-.7,2.339l-1.528,2.912L17.9,71.659l1.389-2.645a3.42,3.42,0,0,0,.43-1.313,1.611,1.611,0,0,0-.236-1.01,2.191,2.191,0,0,0-.858-.74,2.29,2.29,0,0,0-1.113-.294,1.669,1.669,0,0,0-1,.368,3.279,3.279,0,0,0-.851,1.094l-.952,1.81L23.962,73.8Zm-1.977-8.231,6.36-.1-1.16,2.2-6.334.052Z"
        fill="#0091fc"
      />
      <path
        id="Path_489"
        data-name="Path 489"
        d="M18.931,57.66,28.77,60.4l.07-.1-6.271-8.059L23.936,50.2l7.812,10.463-1.534,2.288L17.567,59.694Z"
        fill="#0091fc"
      />
      <path
        id="Rectangle_230"
        data-name="Rectangle 230"
        d="M25.976,47.723l1.389-1.747,9.643,7.665-1.389,1.748Z"
        fill="#0091fc"
      />
      <path
        id="Path_490"
        data-name="Path 490"
        d="M40.135,37.97l-1.54,1.639a2.828,2.828,0,0,0-.959-.414,2.644,2.644,0,0,0-.974-.031,2.712,2.712,0,0,0-.93.328,3.513,3.513,0,0,0-.835.663,3.214,3.214,0,0,0-.852,1.642,3.329,3.329,0,0,0,.238,1.891,6.571,6.571,0,0,0,3.452,3.238,3.277,3.277,0,0,0,1.893.113,3.237,3.237,0,0,0,1.569-.957,3.507,3.507,0,0,0,.6-.855,2.8,2.8,0,0,0,.279-.935,2.622,2.622,0,0,0-.068-.963,2.708,2.708,0,0,0-.449-.928l1.549-1.632a4.863,4.863,0,0,1,1.074,3.25,4.961,4.961,0,0,1-.413,1.774,5.836,5.836,0,0,1-1.132,1.687,5.611,5.611,0,0,1-2.534,1.6,5.24,5.24,0,0,1-2.958-.023,8.263,8.263,0,0,1-4.961-4.671,5.283,5.283,0,0,1-.2-2.955,5.583,5.583,0,0,1,1.428-2.619,6.163,6.163,0,0,1,1.521-1.2,4.932,4.932,0,0,1,1.7-.571,4.618,4.618,0,0,1,1.77.1,5.127,5.127,0,0,1,1.737.825"
        fill="#0091fc"
      />
      <path
        id="Path_491"
        data-name="Path 491"
        d="M48.014,41.829l-8.028-9.344,6.077-5.222,1.22,1.419L42.9,32.45l2.179,2.536,4.07-3.5L50.365,32.9,46.3,36.4l2.191,2.55,4.421-3.8,1.218,1.419Z"
        fill="#0091fc"
      />
      <path
        id="Path_492"
        data-name="Path 492"
        d="M56.664,23.723a1.648,1.648,0,0,0-1.282-.6,2.743,2.743,0,0,0-1.575.565,3.066,3.066,0,0,0-.86.859,1.777,1.777,0,0,0-.3.906,1.382,1.382,0,0,0,.25.83,1.22,1.22,0,0,0,.528.445,1.534,1.534,0,0,0,.67.115,3.425,3.425,0,0,0,.745-.12,7.56,7.56,0,0,0,.744-.249l1.112-.425a8.057,8.057,0,0,1,1.354-.41,4.844,4.844,0,0,1,1.351-.093,3.117,3.117,0,0,1,1.25.367,3.054,3.054,0,0,1,1.047.972,3.329,3.329,0,0,1,.636,1.878,3.629,3.629,0,0,1-.57,1.963,6.235,6.235,0,0,1-1.8,1.822,6.391,6.391,0,0,1-2.3,1.063,3.7,3.7,0,0,1-2.1-.121,3.83,3.83,0,0,1-1.717-1.339l1.8-1.257a1.859,1.859,0,0,0,.939.65,2.1,2.1,0,0,0,1.113,0,3.57,3.57,0,0,0,1.153-.549,3.445,3.445,0,0,0,.922-.912,2.041,2.041,0,0,0,.356-1,1.472,1.472,0,0,0-.267-.94,1.2,1.2,0,0,0-.688-.491,2.129,2.129,0,0,0-.936.008,7.908,7.908,0,0,0-1.168.351l-1.356.507a5.591,5.591,0,0,1-2.65.4,2.718,2.718,0,0,1-1.937-1.234,3.162,3.162,0,0,1-.607-1.9,3.9,3.9,0,0,1,.6-1.967,5.624,5.624,0,0,1,1.638-1.7,5.458,5.458,0,0,1,2.145-.946,3.776,3.776,0,0,1,2,.14,3.223,3.223,0,0,1,1.534,1.176Z"
        fill="#0091fc"
      />
      <path
        id="Path_493"
        data-name="Path 493"
        d="M70.081,25.956l-5.5-11.026,4.134-2.062a4.89,4.89,0,0,1,2.37-.592,3.563,3.563,0,0,1,1.956.647,4.692,4.692,0,0,1,1.873,3.761,3.546,3.546,0,0,1-.673,1.952,4.993,4.993,0,0,1-1.913,1.543l-2.74,1.367L68.77,19.9l2.471-1.233a2.811,2.811,0,0,0,1.088-.865,1.832,1.832,0,0,0,.348-1.061,2.54,2.54,0,0,0-.289-1.153,2.5,2.5,0,0,0-.744-.92,1.8,1.8,0,0,0-1.054-.345,2.927,2.927,0,0,0-1.349.361L67.41,15.6l4.668,9.355Z"
        fill="#0091fc"
      />
      <path
        id="Path_494"
        data-name="Path 494"
        d="M80.389,21,76.024,9.479l7.492-2.838.663,1.75-5.4,2.047,1.184,3.128,5.017-1.9.662,1.75-5.017,1.9,1.192,3.145L87.268,16.4l.663,1.75Z"
        fill="#0091fc"
      />
      <path
        id="Path_495"
        data-name="Path 495"
        d="M90.567,17.288,87.391,5.381l4.463-1.193a5.209,5.209,0,0,1,2.432-.139,3.414,3.414,0,0,1,1.769.957,4.163,4.163,0,0,1,1.011,1.868,4.041,4.041,0,0,1,.045,2.114,3.337,3.337,0,0,1-1.08,1.68,5.468,5.468,0,0,1-2.192,1.073l-3.178.847L90.183,10.8l2.888-.771a3.406,3.406,0,0,0,1.253-.575,1.607,1.607,0,0,0,.583-.859,2.2,2.2,0,0,0-.036-1.132,2.307,2.307,0,0,0-.54-1.018,1.667,1.667,0,0,0-.945-.482A3.267,3.267,0,0,0,92,6.078l-1.976.528,2.7,10.1Zm4.71-7.034,4.384,4.6-2.4.641-4.333-4.621Z"
        fill="#0091fc"
      />
      <path
        id="Path_496"
        data-name="Path 496"
        d="M99.635,4.21l-.289-1.847L109.056.84l.29,1.847-3.762.59L107.2,13.6l-2.187.344L103.4,3.62Z"
        fill="#0091fc"
      />
      <path
        id="Path_497"
        data-name="Path 497"
        d="M113.351,12.761,112.8.456l2.229-.1.234,5.21,5.7-.255L120.733.1l2.236-.1.551,12.306-2.235.1-.235-5.228-5.7.255.234,5.227Z"
        fill="#0091fc"
      />
      <path
        id="Path_498"
        data-name="Path 498"
        d="M134.707,9.449a2.558,2.558,0,0,1-1.289-.484,2.708,2.708,0,0,1-.863-1.048,2.655,2.655,0,0,1,.258-2.65,2.728,2.728,0,0,1,1.047-.859,2.658,2.658,0,0,1,2.65.256,2.722,2.722,0,0,1,.859,1.045,2.654,2.654,0,0,1-.257,2.648,2.726,2.726,0,0,1-1.044.863,2.544,2.544,0,0,1-1.361.227"
        fill="#0091fc"
      />
      <path
        id="Path_473-2"
        data-name="Path 473"
        d="M98.965,232.4l-3.107,11.924-7.637-1.99.472-1.811,5.477,1.429.843-3.236-4.953-1.292.472-1.81,4.953,1.291,1.32-5.063Z"
        transform="translate(219.943 257.172) rotate(-173)"
        fill="#0091fc"
      />
    </svg>
  </div>
)

export default ManagedIT
